/* stylelint-disable no-invalid-position-at-import-rule */
/* stylelint-disable no-descending-specificity */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'nanaykuna-ui-kit/dist/nanaykuna-ui-kit/nanaykuna-ui-kit.css';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}

.table-order {
  tbody tr:nth-child(odd) {
    background-color: #fff;
  }

  tbody tr:nth-child(even) {
    background-color: #f7f9fc;
  }

  tbody tr:hover {
    background-color: #edf4fb;
  }

  tbody tr td select {
    background-color: transparent;
  }

  tbody tr td,
  thead tr th {
    border-collapse: collapse;
  }

  thead tr:first-child {
    th:first-child {
      border-radius: 8px 0 0;
    }

    th:last-child {
      border-radius: 0 8px 0 0;
    }
  }

  tbody tr:last-child {
    td:first-child {
      border-radius: 0 0 0 8px;
    }

    td:last-child {
      border-radius: 0 0 8px;
    }
  }

  tbody tr td {
    border-top: none;
  }

  tbody tr td + td {
    border-left: none;
  }

  tbody tr td:first-child {
    border-top: none;
  }
}

.table-loading {
  tbody tr:nth-child(odd) {
    background-color: #fff;
  }

  tbody tr:nth-child(even) {
    background-color: #f7f9fc;
  }

  thead tr:first-child {
    th:first-child {
      border-radius: 8px 0 0;
    }

    th:last-child {
      border-radius: 0 8px 0 0;
    }
  }

  tbody tr:last-child {
    td:first-child {
      border-radius: 0 0 0 8px;
    }

    td:last-child {
      border-radius: 0 0 8px;
    }
  }

  tbody tr td {
    border-top: none;
  }

  tbody tr td + td {
    border-left: none;
  }

  tbody tr td:first-child {
    border-top: none;
  }
}

.mat-mdc-menu-content {
  background-color: transparent !important;
  padding: 0 !important;
  border-radius: 8px !important;
}

.center-table-order .mat-sort-header-container {
  justify-content: center !important;
  align-items: center !important;
}

.transparent-dialog .mat-mdc-dialog-surface {
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0;
}
